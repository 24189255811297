/**
 *  Kinty UI / Docs / Components / Header
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The header
 *
 */

/** Dependencies */
import React from "react";
import {
  IconButton,
  Header as UIHeader,
  HeaderDivider,
  Icons,
  Theme,
  Hairline,
} from "@kinty-app/ui";

/** Local Dependencies */
import { Logo } from "../Logo/Logo";
import packageJson from "../../../../package.json";

/** Styles */
import * as styles from "./Header.module.scss";

/** Props */
interface HeaderProps {
  onThemeToggle?: () => void;
  theme?: Theme;
}

export const Header: React.FC<HeaderProps> = ({
  onThemeToggle,
  theme = Theme.LIGHT,
}) => (
  <UIHeader fixed className={styles.root}>
    <Logo />
    <HeaderDivider />
    <div className={styles.content}>
      <Hairline level="2">v{packageJson.version}</Hairline>
      <IconButton
        icon={
          theme === Theme.LIGHT
            ? Icons.brightnessDownLine
            : Icons.brightnessUpLine
        }
        onClick={onThemeToggle}
      />
    </div>
  </UIHeader>
);
