/**
 *  Kinty UI / Docs / Components / Code
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Inline or block of code
 *
 */

/** Dependencies */
import React from "react";
import classNames from "classnames";
import { Language } from "prism-react-renderer";

/** Styles */
import * as styles from "./Code.module.scss";
import { SyntaxHighlighter } from "../SyntaxHighlighter/SyntaxHighlighter";

interface CodeProps {
  inline?: boolean;
  className?: string;
  highlight?: boolean;
  codeString?: string;
  language?: Language;
  children?: React.ReactNode;
}

export const Code: React.FC<CodeProps> = ({
  inline,
  children,
  codeString,
  language,
  highlight,
  className,
}) => (
  <div className={classNames(styles.root, inline && styles.inline, className)}>
    {highlight && codeString && language ? (
      <SyntaxHighlighter
        codeString={codeString}
        language={language}
        className={styles.code}
      />
    ) : (
      <pre className={styles.code}>{children}</pre>
    )}
  </div>
);
