/**
 *  Kinty UI / Docs / Components / Navigation
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The navigation component
 *
 */

/** Dependencies */
import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import {
  Icons,
  IconType,
  ContentNavigationLink,
  Hairline,
} from "@kinty-app/ui";

/** Styles */
import * as styles from "./Navigation.module.scss";

/** Navigation */
export const navigationQuery = graphql`
  query Navigation {
    allMdx(sort: { fields: frontmatter___title }) {
      edges {
        node {
          id
          slug
          frontmatter {
            title
            type
            icon
          }
        }
      }
    }
  }
`;

/** Props */
interface NavigationProps {
  className?: string;
}

export const Navigation: React.FC<NavigationProps> = ({ className }) => {
  const navigation =
    useStaticQuery<GatsbyTypes.NavigationQuery>(navigationQuery);
  const nodes = navigation.allMdx.edges.map(({ node }) => node);

  const sections = nodes.reduce(
    (sections: string[], node) => {
      const name = node.frontmatter?.type;
      if (!name || sections.includes(name)) {
        return sections;
      }
      return sections.concat([name]);
    },
    ["Page"]
  );

  return (
    <div className={className}>
      {sections.map((name) => (
        <div className={styles.section} key={name}>
          <Hairline level="2" className={styles.title}>
            {name === "Page" ? `${name}s` : name}
          </Hairline>
          {nodes
            .filter((node) => node.frontmatter?.type === name)
            .map((node) => (
              <ContentNavigationLink
                icon={
                  (Icons[
                    node.frontmatter?.icon as keyof typeof Icons
                  ] as IconType) || Icons.noneLine
                }
                to={`/${node.slug || ""}/`.replace(/\/\//, "/")}
                activeClassName="active"
                component={Link}
                key={node.id}
              >
                {node.frontmatter?.title}
              </ContentNavigationLink>
            ))}
        </div>
      ))}
    </div>
  );
};
