/**
 *  Kinty UI / Docs / Components / Prism Theme
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Renders the prism theme inside
 *
 */

/** Dependencies */
import React from "react";
import classNames from "classnames";

/** Styles */
import * as styles from "./PrismTheme.module.scss";

export const PrismTheme: React.FC<React.ComponentPropsWithoutRef<"div">> = ({
  className,
  ...props
}) => <div className={classNames(styles.root, className)} {...props} />;
