/**
 *  Kinty UI / Docs / Components / Divider
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  A custom divider element
 *
 */

/** Dependencies */
import React from "react";

interface DividerProps {
  height?: number;
}

export const Divider: React.FC<DividerProps> = ({ height = 10 }) => (
  <div style={{ height }} />
);
