/**
 *  Kinty UI / Docs / Components / Content
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Content is used to render the body of the documentation page in MDX
 *
 */

/** Dependencies */
import React from "react";
import { MDXProvider } from "@mdx-js/react";
import {
  Body,
  Caption,
  TableHeadCell,
  Table,
  TableRow,
  TableCell,
  Link,
  Separator,
} from "@kinty-app/ui";
import classNames from "classnames";
import { preToCodeBlock } from "mdx-utils";

/** Local Dependencies */
import { Divider } from "../Divider/Divider";
import { Code } from "../Code/Code";
import { LiveCode } from "../LiveCode/LiveCode";

/** Styles */
import * as styles from "./Content.module.scss";

/** Props */
interface ContentProps {
  children?: React.ReactNode;
}

export const Content: React.FC<ContentProps> = ({ children }) => (
  <MDXProvider
    components={{
      h1: (props) => (
        <Body level="1" bold className={styles.block} {...props} />
      ),
      h2: (props) => (
        <Body level="2" bold className={styles.block} {...props} />
      ),
      h3: (props) => <Caption className={styles.block} {...props} />,
      h4: (props) => <Caption className={styles.block} {...props} />,
      p: (props) => <Caption className={styles.block} {...props} />,
      hr: (props) => <Separator className={styles.block} {...props} />,
      ul: (props) => <ul className={styles.block} {...props} />,
      ol: (props) => <ol className={styles.block} {...props} />,
      li: (props) => <li {...props} />,
      table: (props) => <Table {...props} />,
      th: (props) => <TableHeadCell {...props} />,
      td: (props) => <TableCell {...props} />,
      tr: (props) => <TableRow {...props} />,
      a: (props) => <Link {...props} />,
      blockquote: ({ children, ...props }) => (
        <Caption
          level="2"
          className={classNames(styles.block, styles.quote)}
          {...props}
        >
          {children}
        </Caption>
      ),
      pre: (preProps) => {
        const props = preToCodeBlock(preProps);

        if (!props || ((!props.language || !props.codeString) && !props.live)) {
          return <Code className={styles.block} {...preProps} />;
        }

        return props.live ? (
          <LiveCode
            className={styles.block}
            editor={props.editor !== "false"}
            center={!!props.center}
            background={props.background}
            codeString={props.codeString}
            padding={!!props.padding}
          />
        ) : (
          <Code
            highlight
            className={styles.block}
            codeString={props.codeString}
            language={props.language}
          />
        );
      },
      inlineCode: (props) => <Code inline {...props} />,
      Divider,
    }}
  >
    {children}
  </MDXProvider>
);
