/**
 *  Kinty UI / Docs / Components / Live Code
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Live code component
 *
 */

/** Dependencies */
import React from "react";
import classNames from "classnames";
import { LiveProvider, LiveEditor, LiveError, LivePreview } from "react-live";
import { useMDXComponents } from "@mdx-js/react";
import * as ui from "@kinty-app/ui";
import md5 from "md5";

/** Local Dependencies */
import { dummyTheme } from "../SyntaxHighlighter/SyntaxHighlighter";
import { PrismTheme } from "../PrismTheme/PrismTheme";
import { Logo } from "../Logo/Logo";

/** Styles */
import * as styles from "./LiveCode.module.scss";

/** Styles for the live editor */
const liveEditorStyles = {
  fontSize: "14px",
  fontFamily: "Source Code Pro, monospace",
  lineHeight: "20px",
};

interface LiveCodeProps {
  className?: string;
  codeString?: string;
  editor?: boolean;
  background?: string;
  center?: boolean;
  padding?: boolean;
}

export const LiveCode: React.FC<LiveCodeProps> = ({
  codeString,
  className,
  editor = true,
  background = "card",
  center,
  padding,
}) => {
  const components = useMDXComponents();

  return (
    <PrismTheme>
      <div className={classNames(styles.root, className)}>
        <LiveProvider
          code={codeString}
          transformCode={(code) => "/** @tsx mdx */" + code}
          theme={dummyTheme}
          scope={{ ...components, ...ui, md5, Logo }}
        >
          <LivePreview
            className={classNames(
              styles.preview,
              center && styles.center,
              padding && styles.padded,
              background === "layout" && styles.bgLayout
            )}
          />
          {editor && (
            <LiveEditor className={styles.editor} style={liveEditorStyles} />
          )}
          <LiveError className={styles.error} />
        </LiveProvider>
      </div>
    </PrismTheme>
  );
};
