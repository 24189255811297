/**
 *  Kinty UI / Docs / Layout
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The Layout component for the documentation
 *
 */

/** Dependencies */
import React, { useCallback, useState } from "react";
import {
  Content as UIContent,
  ThemeProvider,
  Headline,
  Theme,
  Card,
} from "@kinty-app/ui";
import Helmet from "react-helmet";
import "normalize.css";
import "../../../dist/index.css";

/** Local Dependencies */
import { Navigation } from "../components/Navigation/Navigation";
import { Header } from "../components/Header/Header";
import { Content } from "../components/Content/Content";

/** Styles */
import * as styles from "./index.module.scss";

/** Props */
interface LayoutProps {
  children?: React.ReactNode;
  pageContext: {
    frontmatter?: {
      title: string;
      type: string;
    };
  };
}

const Layout: React.FC<LayoutProps> = ({ children, pageContext }) => {
  const [theme, setTheme] = useState<Theme>(Theme.LIGHT);
  const { title, type } = pageContext.frontmatter || {};
  const documentTitle =
    (type === "Page" ? title : `${type} / ${title}`) + " - Kinty UI";

  const handleThemeToggle = useCallback(() => {
    setTheme(theme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT);
  }, [theme]);

  return (
    <ThemeProvider theme={theme}>
      <Helmet title={documentTitle} />
      <Header onThemeToggle={handleThemeToggle} theme={theme} />
      <UIContent>
        <div className={styles.body}>
          <div className={styles.navigation}>
            <Navigation />
          </div>
          <div className={styles.content}>
            <Card>
              <Headline level="3" className={styles.title}>
                {pageContext.frontmatter?.title}
              </Headline>
              <Content>{children}</Content>
            </Card>
          </div>
        </div>
      </UIContent>
    </ThemeProvider>
  );
};

export default Layout;
